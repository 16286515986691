<template>
  <b-row>
    <b-col cols="12" class="mt-1">
      <div class="d-flex justify-content-end">
        <b-button variant="primary" v-b-modal.modal-main class="my-1">
          Add Supplier
        </b-button>
      </div>

      <b-modal id="modal-main" cancel-variant="outline-secondary" centered title="Add Supplier" hide-footer>
        <validation-observer ref="AddSupplierForm" #default="{ invalid }">
          <b-form @submit.prevent>
            <b-form-group>
              <label for="email">Supplier Name:</label>
              <validation-provider #default="{ errors }" name="name" vid="name" rules="required">
                <b-form-input id="name" type="text" placeholder="Supplier Name" v-model="name" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group>
              <label for="email">Email:</label>
              <validation-provider #default="{ errors }" name="email" vid="email" rules="email">
                <b-form-input id="email" type="email" placeholder="Email Address" v-model="email" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group>
              <label for="email">Address:</label>
              <validation-provider #default="{ errors }" name="Address" vid="Address" rules="required">
                <b-form-input id="address" type="text" placeholder="Address" v-model="address" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group>
              <label for="email">Phone Number:</label>
              <validation-provider #default="{ errors }" name="Phone" vid="Phone" rules="required">
                <b-form-input id="phoneNumber" type="number" placeholder="Phone number" v-model="phoneNumber" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group>
              <label for="email">Goods Supplied:</label>
              <validation-provider #default="{ errors }" name="Goods" vid="Goods" rules="required">
                <b-form-input id="goodsSupplied" type="text" placeholder="goods:" v-model="goodsSupplied" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group>
              <label for="email">Contact Person:</label>
              <validation-provider #default="{ errors }" name="Contact" vid="Contact" rules="required">
                <b-form-input id="contactPerson" type="text" placeholder="contact:" v-model="contactPerson" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" block :disabled="invalid" @click="AddSupplier()">
                <b-spinner small v-if="loading" class="mx-2" />Add
              </button>
            </div>
          </b-form>
        </validation-observer>
      </b-modal>

      <b-modal id="modal-edit" cancel-variant="outline-secondary" ok-title="Edit" cancel-title="Close" centered
        title="Edit Supplier" hide-footer @hidden="reloadPage()">
        <validation-observer ref="EditSupplierForm" #default="{ invalid }">
          <b-form @submit.prevent>
            <b-form-group>
              <label for="email">Supplier Name:</label>
              <validation-provider #default="{ errors }" name="name" vid="name" rules="required">
                <b-form-input id="name" type="text" placeholder="Supplier Name" v-model="supplierData.name" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group>
              <label for="email">Email:</label>
              <validation-provider #default="{ errors }" name="email" vid="email" rules="required | email">
                <b-form-input id="email" type="email" placeholder="Email Address" :value="supplierData.email"
                  v-model="supplierData.email" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group>
              <label for="email">Address:</label>
              <validation-provider #default="{ errors }" name="Address" vid="Address" rules="required">
                <b-form-input id="address" type="text" placeholder="John Doe" v-model="supplierData.address" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group>
              <label for="email">Phone Number:</label>
              <validation-provider #default="{ errors }" name="Phone" vid="Phone" rules="required">
                <b-form-input id="phoneNumber" type="number" placeholder="Phone number"
                  v-model="supplierData.phoneNumber" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group>
              <label for="email">Goods Supplied:</label>
              <validation-provider #default="{ errors }" name="Goods" vid="Goods" rules="required">
                <b-form-input id="goodsSupplied" type="text" placeholder="goods:" v-model="supplierData.goodsSupplied" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group>
              <label for="email">Contact Person:</label>
              <validation-provider #default="{ errors }" name="Contact" vid="Contact" rules="required">
                <b-form-input id="contactPerson" type="text" placeholder="contact:"
                  v-model="supplierData.contactPerson" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" block :disabled="invalid" @click="EditSupplier()">
                <b-spinner small v-if="loading" class="mx-2" />Edit
              </button>
            </div>
          </b-form>
        </validation-observer>
      </b-modal>
    </b-col>
    <b-col md="2" sm="4" class="my-1">
      <b-form-group class="mb-0">
        <label class="d-inline-block text-sm-left mr-50">Per page</label>
        <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
      </b-form-group>
    </b-col>
    <b-col md="4" sm="8"> </b-col>
    <!-- <b-col md="4" sm="8" class="my-1">
      <b-form-group label="Sort" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="sortBySelect"
        class="mb-0">
        <b-input-group size="sm">
          <b-form-select id="sortBySelect" v-model="sortBy" :options="sortOptions" class="w-75">
            <template v-slot:first>
              <option value="">
                -- none --
              </option>
            </template>
          </b-form-select>
          <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
            <option :value="false">
              Asc
            </option>
            <option :value="true">
              Desc
            </option>
          </b-form-select>
        </b-input-group>
      </b-form-group>
    </b-col> -->
    <b-col md="6" class="my-1">
      <b-form-group label="Filter" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput"
        class="mb-0">
        <b-input-group size="sm">
          <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-col>

    <b-col cols="12">
      <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="supplierList"
        :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
        :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
        <template #cell(avatar)="data">
          <b-avatar :src="data.value" />
        </template>

        <template #cell(status)="data">
          <b-badge :variant="status[1][data.value]">
            {{ status[0][data.value] }}
          </b-badge>
        </template>

        <template #cell(actions)="data">
          <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
            <template v-slot:button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
            </template>
            <b-dropdown-item v-b-modal.modal-edit @click="getSupplierData(data.item)">
              <feather-icon icon="Edit2Icon" class="mr-50" />
              <span>Edit</span>
            </b-dropdown-item>
            <b-dropdown-item @click="removeSupplier(data.item.id)">
              <feather-icon icon="TrashIcon" class="mr-50" />
              <span>Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <div class="d-flex justify-content-center mb-1">
        <b-spinner variant="success" label="Spinning" block v-if="loading" style="width: 3rem; height: 3rem"></b-spinner>
      </div>
    </b-col>

    <b-col cols="12">
      <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm"
        class="my-0" />
        <b-form-group>
      Showing {{ perPage * (currentPage - 1) + 1}} 
      to {{ Math.min(perPage * currentPage, totalRows) }} 
      of {{ totalRows }} entries
        </b-form-group>
    </b-col>

    <b-col cols="12"> </b-col>
  </b-row>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BTabs,
  BTab,
  BCardImg,
  BCardFooter,
  BModal,
  BSpinner,
  BDropdown,
  BDropdownItem,
  BForm,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapActions, mapGetters } from "vuex";
import { required } from "@validations";
export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BModal,
    BSpinner,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BForm,

    ///////
    required,
  },
  data() {
    return {
      name: "",
      email: "",
      address: "",
      phoneNumber: "",
      goodsSupplied: "",
      contactPerson: "",
      perPage: 5,
      supplierData: [],
      pageOptions: [3, 5, 10],
      totalRows: 0,
      currentPage: 0,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        "name",
        "email",
        "address",
        "phoneNumber",
        "goodsSupplied",
        "contactPerson",
        "Actions",
      ],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    ...mapGetters("supplierModule", {
      supplierList: "suppliers",
      loading: "loading",
    }),
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  methods: {
    ...mapActions("supplierModule", [
      "getSupplierListAction",
      "removeSupplierAction",
      "addSupplierAction",
      "updateSupplierAction",
    ]),

    successEdit() {
      this.$swal({
        title: "Supplier edited!",
        text: "You have successfully edited a supplier!",
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },

    // error
    errorEdit() {
      this.$swal({
        title: "Error!",
        text: " Failed!",
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },

    getSupplierData(data) {
      this.supplierData = data;
    },

    successAdd() {
      this.$swal({
        title: "Supplier Added!",
        text: "You have successfully added a supplier!",
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },

    errorAdd() {
      this.$swal({
        title: "Error!",
        text: " Failed!",
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    reloadPage(){
      this.getSupplierListAction();
    },
    AddSupplier() {
      let payload = {
        name: this.name,
        email: this.email,
        address: this.address,
        phoneNumber: this.phoneNumber,
        goodsSupplied: this.goodsSupplied,
        contactPerson: this.contactPerson,
      };
      this.addSupplierAction(payload)
        .then(() => {
          this.successAdd();
          this.name = "",
            this.email = "",
            this.address = "",
            this.phoneNumber = "",
            this.goodsSupplied = "",
            this.contactPerson = "",
            this.$bvModal.hide("modal-main");
        })
        .catch(() => {
          this.errorAdd();
          this.name = "",
            this.email = "",
            this.address = "",
            this.phoneNumber = "",
            this.goodsSupplied = "",
            this.contactPerson = "",
            this.$bvModal.hide("modal-main");
        });
    },

    EditSupplier() {
      // let payload = {
      //   name: this.supplierData.name,
      //   email: this.supplierData.email,
      //   address: this.supplierData.address,
      //   phoneNumber: this.supplierData.phoneNumber,
      //   goodsSupplied: this.supplierData.goodsSupplied,
      //   contactPerson: this.supplierData.contactPerson
      // }
      let payload = this.supplierData;
      this.updateSupplierAction(payload)
        .then(() => {
          this.successEdit();
          this.$bvModal.hide("modal-edit");
        })
        .catch(() => {
          this.successEdit();
          this.$bvModal.hide("modal-edit");
        });
    },

    removeSupplier(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.removeSupplierAction(id).then(() => {
            this.$swal({
              title: "Deleted!",
              text: "This supplier has been deleted.",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          }).catch((error) => {
            this.$swal({
              title: "Error!",
              text: " Failed! " + error.response.data.message,
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          });
        
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Cancelled",
            text: "Supplier is safe :)",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      });
    },

    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  async mounted() {
    await this.getSupplierListAction()
      .then(() => {
        this.totalRows = this.supplierList.length;
      })
      .catch((response) => {
        console.log(response);
      });
  },
};
</script>
