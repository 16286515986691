<template>

  <div>



    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
     



          <div class="m-2">

            <!-- Table Top -->
            <b-row>

              <!-- Per Page -->



              <!-- Search -->

              <b-col cols="12" class="mt-1">
                
                <TableListClients />
              </b-col>
            </b-row>

          </div>
      

    </b-card>
  </div>
</template>
  
<script>

import TableListClients from './TableListClients.vue'
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTabs, BTab, BCardImg, BCardFooter, BModal, VBModal
} from 'bootstrap-vue'


export default {
  components: {
    TableListClients,
    BTabs,
    BModal,
    BTab,
    BCard,
    BCardImg,
    BCardFooter,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
  },
}
</script>
  
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
  
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
  